import React from 'react';
import { iconMap, titleMap } from '@/config/index';
import { CloseCircleFilled, FormOutlined } from '@ant-design/icons';
import './CustomNode.less';

const CustomNode = (props) => {
    const { node, setOpenDrawer} = props;
    const { type, viewData } = node.getData();
    const onClick = () => {};
    const deleteNode = (e) => {
      e.stopPropagation();
      node.remove();
    };

    return (
    <div className="react-node" style={{backgroundColor: viewData?.color ?? "#007e74",border: `2px solid ${viewData?.color ?? "#007e74"}`}} onClick={onClick}>
      <CloseCircleFilled onClick={deleteNode} className='close' />
      <div className="node-content">
        <div className="icon" style={{background: viewData?.color ?? "#007e74"}}>
          { type ? iconMap[type] : null }
        </div>
        <div className="text">
          <div className="header">{viewData?.name ?? titleMap[type]}</div>
          <div className="desc">{viewData?.name ?? titleMap[type]}</div>
        </div>
        <div className="edit-icon" onClick={() => setOpenDrawer(true, node)}>
          <FormOutlined style={{color: viewData?.color ?? "#007e74"}} />
        </div>
      </div>
    </div>
  )
}
export default CustomNode;
const SvgComponent = (props) => {
    const { color } = props;
    return (
        <div className="img">
            <svg t="1713411136914" viewBox="0 0 1024 1024" p-id="5142" width="60" height="60" >
                <path d="M619.787898 44.781759l359.427055 359.500183a152.473125 152.473125 0 0 1 0 215.583104L619.787898 979.292101a152.473125 152.473125 0 0 1-215.656232 0L44.704611 619.791918a152.473125 152.473125 0 0 1 0-215.583104L404.204794 44.781759a152.473125 152.473125 0 0 1 215.656233 0z" fill={color} p-id="5143" data-spm-anchor-id="a313x.search_index.0.i6.61923a81u5yoIF" class="">
                </path>
            </svg>
        </div>
    )
};
export default SvgComponent;
import React from 'react';
import { useGraphStore } from '@antv/xflow';
import { AppstoreOutlined, ApiOutlined, CloudDownloadOutlined } from '@ant-design/icons';
import { FloatButton, message } from 'antd';
import { set } from '@/services/idb';
import { cloneDeep } from 'lodash';

const FlowMenu = () => {
  const [messageApi, contextHolder] = message.useMessage();
  const nodes = useGraphStore((state) => state.nodes);
  const edges = useGraphStore((state) => state.edges);

  const saveFlowChart = async () => {
    const graphData = { nodes, edges };
    graphData.nodes = graphData.nodes.map(node => {
      const copyNode = cloneDeep(node);
      if (copyNode.component) delete copyNode.component;
      return copyNode;
    })
    graphData.edges = graphData.edges.map(edge => {
      const copyEdge = cloneDeep(edge);
      copyEdge.zIndex = 0;
      return copyEdge;
    })
    try {
      await set('flowChart', graphData)
      messageApi.open({  type: 'success', content: '保存成功'});
    } catch (error) {
      messageApi.open({  type: 'error', content: '保存失败'});
      console.error(error);
    }
  }

  return <>
    {contextHolder}
    <FloatButton.Group
      trigger="click"
      type="primary"
      style={{ right: 24 }}
      icon={<AppstoreOutlined />}
    >
      <FloatButton tooltip='测试' icon={<ApiOutlined />}/>
      <FloatButton tooltip='保存' onClick={saveFlowChart} icon={<CloudDownloadOutlined />}/>
    </FloatButton.Group>
  </>
};

export default FlowMenu;
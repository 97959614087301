import { useGraphInstance, useGraphEvent, useGraphStore } from '@antv/xflow';
import { createStartNode, createEndNode } from '@/config/index';
import { useEffect } from 'react';
import { get } from '@/services/idb';

const InitNode = () => {
  const graph = useGraphInstance();
  const initData = useGraphStore((state) => state.initData);

  useGraphEvent('edge:mouseenter', ({ edge }) => {
    edge.addTools(['vertices', {
      name: 'button-remove',
      args: { distance: -40 },
    },
    'source-arrowhead',
    {
      name: 'target-arrowhead',
      args: {
        attrs: {
          fill: 'red',
        },
      },
    }])
  })

  useGraphEvent('edge:mouseleave', ({ edge }) => {
    edge.removeTool('vertices')
    edge.removeTool('button-remove')
    edge.removeTool('source-arrowhead')
    edge.removeTool('target-arrowhead')
  })

  useEffect(() => {
    async function getFlowChart() {
      if (graph) {
        const data = await get('flowChart');
        if (data) {
          initData(data)
        } else {
          const startNode = createStartNode(graph.view);
          const endNode = createEndNode(graph.view);
          graph.addNodes([startNode, endNode]);
        }
        graph.cleanHistory();
      }
    }
    getFlowChart()
  }, [graph, initData])

  return null
}

export default InitNode
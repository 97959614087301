import { openDB } from 'idb';

const dbPromise = openDB('flowChart-store', 1, {
  upgrade(db) {
    db.createObjectStore('flowChart');
  },
});

export async function get(key) {
  return (await dbPromise).get('flowChart', key);
}
export async function set(key, val) {
  return (await dbPromise).put('flowChart', val, key);
}
export async function del(key) {
  return (await dbPromise).delete('flowChart', key);
}
export async function clear() {
  return (await dbPromise).clear('flowChart');
}
export async function keys() {
  return (await dbPromise).getAllKeys('flowChart');
}
import './App.css';
import Flow from './components/flow'

function App() {
  return (
    <div className="App">
      <Flow></Flow>
    </div>
  );
}

export default App;

import { 
  XFlow, 
  XFlowGraph, 
  Grid, 
  Background, 
  Snapline, 
  Clipboard, 
  History, 
} from '@antv/xflow';
import LeftStencil from '@/components/panel/LeftStencil';
import RightDrawer from '@/components/panel/RightDrawer';
import ToolBar from '@/components/ToolBar/index';
import ShortcutKey from '@/components/Keyboard/index';
import CustomNode from '@/components/node/CustomNode';
import CustomLogicNode from '@/components/node/CustomLogicNode';
import InitNode from '@/components/node/InitNode';
import FlowMenu from '@/components/FlowMenu';
import { register } from '@antv/x6-react-shape';
import { graphConfig } from '@/config/index';
import React, { useCallback, useState } from 'react';

import { Layout } from 'antd';

import '../style/index.less';

const { Content } = Layout;

const Flow = () => {
  const tools = ['zoomOut', 'zoomTo', 'zoomIn', 'zoomToFit', 'zoomToOrigin', 'undo', 'redo', 'ImageDown'];
  const { connectionOptions, connectionEdgeOptions, selectOptions, history: { beforeAddCommand } } = graphConfig;
  const [isOpenDrawer, setOpen] = useState(false);
  const [configNode, setConfigNode] = useState(null);

  const openDrawer = useCallback((boolean, node) => {
    setConfigNode(node);
    setOpen(boolean);
  }, [setOpen, setConfigNode]);

  const closeDrawer = useCallback((boolean) => {
    setConfigNode(null);
    setOpen(boolean);
  }, [setOpen, setConfigNode]);

  register({
    shape: 'custom-portal-react-node',
    width: 100,
    height: 100,
    component: (arg) => <CustomNode {...arg} isOpenDrawer={isOpenDrawer} setOpenDrawer={openDrawer} />,
  });

  register({
    shape: 'custom-portal-react-logic-node',
    width: 60,
    height: 60,
    component: (arg) => <CustomLogicNode {...arg} isOpenDrawer={isOpenDrawer} setOpenDrawer={openDrawer} />,
  });

  return (
    <div className="xflow-guide">
      <XFlow>
        <Layout style={{height: '100vh'}}>
          <LeftStencil></LeftStencil>
          <Content style={{position: 'relative'}}>
              <XFlowGraph
                connectionOptions={connectionOptions}
                connectionEdgeOptions={connectionEdgeOptions} 
                selectOptions={selectOptions}
                pannable={true}
                zoomable minScale={0.5} />
              <Grid type="dot" />
              <Background color="#F2F7FA" />
              <Snapline sharp />
              <History beforeAddCommand={beforeAddCommand} />
              <Clipboard />
              <ToolBar items={tools} />
              <ShortcutKey/>
              <InitNode/>
          </Content>
          <RightDrawer configNode={configNode} open={isOpenDrawer} closeDrawer={closeDrawer}></RightDrawer>
        </Layout>
        <FlowMenu></FlowMenu>
      </XFlow>
    </div>
  )
}

export default Flow
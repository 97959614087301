const portsAttrs = {
    circle: {
        magnet: true,
        r: 5,
        fill: '#fff',
        stroke: '#B8BECC',
        strokeWidth: 1,
    },
};

export const customNodePorts = {
    groups: {
      group1: {
        position: {
          name: 'absolute',
          args: { x: 0, y: 0 },
        },
        attrs: portsAttrs,
      },
      group2: {
        position: {
          name: 'absolute',
          args: { x: 0, y: 0 },
        },
        attrs: portsAttrs,
      },
      group3: {
        position: {
          name: 'absolute',
          args: { x: 0, y: 0 },
        },
        attrs: portsAttrs,
      },
      group4: {
        position: {
          name: 'absolute',
          args: { x: 0, y: 0 },
        },
        attrs: portsAttrs,
      },
    },
    items: [
      { group: 'group1', args: { y: '50%'}, }, // left
      { group: 'group2', args: { x: '50%'}, }, // top
      { group: 'group3', args: { x: '50%', y: '100%' }, }, // bottom
      { group: 'group4', args: { x: '100%', y: '50%' }, }, // right
    ],
}

export const customLogicNodePorts = {
    groups: {
      group1: {
        position: {
          name: 'absolute',
          args: { x: -2, y: 30 },
        },
        attrs: portsAttrs,
      },
      group2: {
        position: {
          name: 'absolute',
          args: { x: 30, y: -2 },
        },
        attrs: portsAttrs,
      },
      group3: {
        position: {
          name: 'absolute',
          args: { x: 62, y: 30 },
        },
        attrs: portsAttrs,
      },
      group4: {
        position: {
          name: 'absolute',
          args: { x: 30, y: 62 },
        },
        attrs: portsAttrs,
      },
    },
    items: [
      { group: 'group1' },
      { group: 'group2' },
      { group: 'group3' },
      { group: 'group4' },
    ],
}
import React, { useEffect, useState, useMemo } from 'react';
import { createForm, onFormValuesChange } from '@formily/core';
import { nodeSchemaMap, schemaModel } from '@/config/nodeSchema';
import { Checkbox, FormItem, Input, FormLayout, Password, Space } from '@formily/antd-v5';
import Query from '@/components/form-components/Query';
import { useGraphStore, useGraphInstance } from '@antv/xflow';
import { FormProvider, createSchemaField } from '@formily/react';
import { ColorPicker } from 'antd';
import { set, cloneDeep } from 'lodash';

const SchemaField = createSchemaField({
  components: {
    Checkbox,
    FormItem,
    Input,
    ColorPicker,
    FormLayout,
    Password,
    Space,
    Query
  },
})

const DynamicForm = (props) => {
  const graph = useGraphInstance();
  const { type, id, isShow } = props;
  const [schema, setSchema] = useState(schemaModel);
  const updateNode = useGraphStore((state) => state.updateNode);
  const curNode = graph.getCellById(id);
  const { businessData, viewData } = curNode.getData();
  const formIntValues = businessData && viewData ? {
    ...viewData,
    ...businessData
  } : {}
  const form = useMemo(
    () =>
      createForm({
        initialValues: formIntValues,
        effects() {
          onFormValuesChange((form) => {
            const { name, color, ...businessData } = form.values;
            const viewData = {};
            if (name) viewData.name = name;
            if (color) viewData.color = typeof color === 'string' ? color : color?.toHexString();
            updateNode(id, {
              data: {
                businessData,
                viewData
              },
            });
          })
        }
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
  
  form.id = id;

  useEffect(() => {
    if (type) {
      const newSchema = cloneDeep(schemaModel);
      set(newSchema, 'properties.layout.properties', nodeSchemaMap[type]);
      setSchema(newSchema);
    }
  }, [type]);

  return (
    <div style={{height: isShow ? 'auto' : 0, overflow: 'hidden'}}>
      <FormProvider form={form}>
        <SchemaField schema={schema} />
      </FormProvider>
    </div>
  )
}

export default DynamicForm
import React, { useState }  from 'react';
import { useDnd } from '@antv/xflow';
import { DoubleRightOutlined, DoubleLeftOutlined } from '@ant-design/icons';
import { Layout, Popover, Collapse } from 'antd';
import CustomNode from '@/components/node/CustomNode';
import CustomLogicNode from '@/components/node/CustomLogicNode';
import { customNodePorts, customLogicNodePorts } from '@/components/node/nodePorts.js';
import { nodesConfig, iconMap, titleMap } from '@/config/index';

import './index.less';

const { Sider } = Layout;
const { Panel } = Collapse;

const LeftStencil =  (props) => {
    const { startDrag } = useDnd();
    const logicNode = ['branch', 'waiting_for_aggregation'];
    const [leftPanelCollapsed, setLeftPanelCollapsed] = useState(false);
    const list = nodesConfig;
    const handleMouseDown = (e,item) => {
      logicNode.includes(item) ?
      startDrag(
        {
          shape: 'custom-portal-react-logic-node',
          width: 60,
          height: 60,
          component: CustomLogicNode,
          data: {
            type: item
          },
          ports: customLogicNodePorts
        },
        e,
      )
      :
      startDrag(
        {
          shape: 'custom-portal-react-node',
          width: 200,
          height: 60,
          component: CustomNode,
          data: {
            type: item
          },
          ports: customNodePorts
        },
        e,
      )
    }
    
    return <Sider
        className='left-stencil'
        width='100'
        collapsedWidth="0"
        collapsed={leftPanelCollapsed}
    >
        <div className="collapsed-button" onClick={() => setLeftPanelCollapsed(!leftPanelCollapsed)}>
        {
          leftPanelCollapsed ? <DoubleRightOutlined /> : <DoubleLeftOutlined />
        }
        </div>
        <Collapse bordered={false} size='small' defaultActiveKey={['access', 'processing','analysis','logic']}>
          {
            list.map((item) => 
            <Panel header={item.label} key={item.key}>
              {
                item.items.map(node => 
                  <Popover content={titleMap[node]} key={node} trigger="hover">
                    <div
                      key={node}
                      className="dnd-item"
                      onMouseDown={(e) => handleMouseDown(e, node)}
                    >
                      {iconMap[node]}
                    </div>
                  </Popover>
                )
              }
            </Panel>
            )
          }
          
        </Collapse>
    </Sider>
}

export default LeftStencil
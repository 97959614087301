import React, { useEffect, useState } from 'react';
import { titleMap } from '@/config/index';
import DynamicForm from '@/components/DynamicForm';
import { useGraphInstance, useGraphEvent } from '@antv/xflow';
import { Drawer } from 'antd';

const RightDrawer = (props) => {
    const { open, closeDrawer, configNode } = props;
    const graph = useGraphInstance();
    const nodes = graph?.getNodes();
    const [type, setType] = useState('');
    const [nodeList, setNodeList] = useState([]);
    const onClose = () => closeDrawer(false);
    const getNodeList = () => graph?.getNodes().filter(node => !["circle", "rect"].includes(node.shape)).map(item => item.id);

    useGraphEvent('node:removed', ({ node }) => {
        setNodeList(getNodeList())
        if (node.id === configNode?.id) onClose();
    });

    useEffect(() => {
        setNodeList(getNodeList())
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [nodes?.length])

    useEffect(() => {
        if (configNode) {
            const { type } = configNode.getData();
            setType(type);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [configNode]);

    return (
        <>
            <Drawer
                title={titleMap[type]}
                width={open ? 720 : 0}
                onClose={onClose}
                open={true}
                mask={false}
                styles={{
                    body: {
                        paddingBottom: 80,
                    }
                }}
            >
                {
                    nodeList && nodeList.map(id => (
                        <DynamicForm key={id} isShow={id === configNode?.id} id={id} type={type}></DynamicForm>
                    ))
                }
            </Drawer>
        </>
    );
};
export default RightDrawer;
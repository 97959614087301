import { useGraphInstance } from '@antv/xflow';
import { Keyboard } from '@antv/x6-plugin-keyboard'
import { useEffect, useCallback } from 'react';

const ShortcutKey = (props) => {
  const graph = useGraphInstance();

  const copy = useCallback(() => {
    const cells = graph.getSelectedCells()
    if (cells.length) {
      graph.copy(cells)
    }
    return false
  }, [graph])

  const paste = useCallback(() => {
    if (!graph.isClipboardEmpty()) {
      const cells = graph.paste({ offset: 32 })
      graph.cleanSelection()
      graph.select(cells)
    }
    return false
  }, [graph])

  const deleteCell = useCallback((arg) => {
    const selectedCells = graph.getSelectedCells().filter((cell) => !['rect', 'circle'].includes(cell.shape));
    graph.removeCells(selectedCells);
  }, [graph])
  
  useEffect(() => {
    graph && graph.use(
      new Keyboard({
        enabled: true,
        global: true,
      })
    )
    .bindKey('ctrl+c', copy)
    .bindKey('ctrl+v', paste)
    .bindKey('command+c', copy)
    .bindKey('command+v', paste)
    .bindKey('del', deleteCell)
    .bindKey('backspace', deleteCell);
  }, [graph, copy, paste, deleteCell]);
  return null
};

export default ShortcutKey;
const baseSchema = {
    "id": {
        "type": "string",
        "title": "ID",
        "x-decorator": "FormItem",
        "x-component": "Input",
        "required": true,
        "x-index": 0
    },
    "name": {
        "type": "string",
        "title": "节点名称",
        "x-decorator": "FormItem",
        "x-component": "Input",
        "required": true,
        "x-index": 1
    },
    "color": {
        "type": "string",
        "title": "节点颜色",
        "x-decorator": "FormItem",
        "x-component": "ColorPicker",
        "x-component-props": {
            'defaultValue': '#007e74',
        },
        "required": true,
        "x-index": 2
    }
}

export const schemaModel = {
    'type': 'object',
    'properties': {
      'layout': {
        "type": "void",
        "x-component": "FormLayout",
        "x-component-props": {
          "labelCol": 4,
          "wrapperCol": 18,
          "feedbackLayout": "loose",
          "layout": "horizontal",
          "labelAlign": "right"
        },
        "properties": {}
      }
    },
}

export const nodeSchemaMap = {
    'get_database_data': {
        ...baseSchema,
        URL: {
            "type": "string",
            "title": "数据库地址",
            "x-decorator": "FormItem",
            "x-component": "Input",
            "x-validator": "url",
            "x-component-props": {},
            "x-decorator-props": {},
            "required": true,
            "x-index": 3
        },
        dataBase: {
            "type": "string",
            "title": "数据库名称",
            "x-decorator": "FormItem",
            "x-component": "Input",
            "x-component-props": {},
            "x-decorator-props": {},
            "required": true,
            "x-index": 4
        },
        query: {
            "type": "string",
            "title": "查询条件",
            "x-decorator": "FormItem",
            "x-component": "Query",
            "x-component-props": {},
            "x-decorator-props": {},
            "required": true,
            "x-index": 5
        },
        condition: {
            type: 'void',
            title: '准入条件',
            'x-decorator': 'FormItem',
            'x-decorator-props': {
                asterisk: true,
                feedbackLayout: 'none',
            },
            'x-component': 'Space',
            properties: {
                admin: {
                    type: 'string',
                    'x-decorator': 'FormItem',
                    'x-component': 'Input',
                    "x-component-props": {
                        "addonBefore": "用户名",
                    },
                    required: true,
                },
                password: {
                    type: 'string',
                    'x-decorator': 'FormItem',
                    "x-component-props": {
                        "addonBefore": "密码",
                    },
                    'x-component': 'Password',
                    required: true,
                },
            },
            "x-index": 6
        },
    },
    'upload_excel_data': {
        ...baseSchema,
    },
    'referencing_script_processing': {
        ...baseSchema,
    },
    'writing_script_processing': {
        ...baseSchema,
    },
    'referencing_sub_processes': {
        ...baseSchema,
    },
    'manual_processing': {
        ...baseSchema,
    },
    'referencing_script_analysis': {
        ...baseSchema,
    },
    'writing_script_analysis': {
        ...baseSchema,
    },
    'manual_analysis': {
        ...baseSchema,
    },
    'accessing_data_analysis_tool': {
        ...baseSchema,
    },
    'branch': {
        ...baseSchema,
    },
    'waiting_for_aggregation': {
        ...baseSchema,
    },
}
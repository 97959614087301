import React from 'react';
import { Circle, X } from 'lucide-react';
import { FormOutlined } from '@ant-design/icons';
import SvgComponent from '@/img/branch.jsx';
import './CustomLogicNode.less';

const CustomLogicNode = (props) => {
    const { node, setOpenDrawer } = props;
    const { type } = node.getData();
    const typeMap = {
      'branch': <X size={40} color="#ffffff" />,
      'waiting_for_aggregation': <Circle size={40} color="#ffffff" />
    }
    
    const onClick = () => {
    }

    return (
    <div className="logic-react-node" onClick={onClick}>
      <SvgComponent className='img' color="#007e74"></SvgComponent>
      <div className="icon">
        {typeMap[type]}
      </div>
      <div className="edit-icon" onClick={() => setOpenDrawer(true, node)}>
        <FormOutlined style={{color: "#007e74"}} />
      </div>
    </div>
  )
}
export default CustomLogicNode;
import {
    InboxOutlined,
    ImportOutlined,
    InteractionOutlined,
    FunctionOutlined,
    PartitionOutlined,
    UserOutlined,
    SnippetsOutlined,
    SlidersOutlined,
    PieChartOutlined,
    DotChartOutlined,
    BranchesOutlined,
    ClusterOutlined,
} from '@ant-design/icons';
import { customNodePorts } from '@/components/node/nodePorts.js';
  
export const nodesConfig = [
    {
        label: '数据接入',
        key: 'access',
        items: [
            'get_database_data',
            'upload_excel_data',
        ]
    },
    {
        label: '数据处理',
        key: 'processing',
        items: [
            'referencing_script_processing',
            'writing_script_processing',
            'referencing_sub_processes',
            'manual_processing',
        ]
    },
    {
        label: '数据分析',
        key: 'analysis',
        items: [
            'referencing_script_analysis',
            'writing_script_analysis',
            'manual_analysis',
            'accessing_data_analysis_tool'
        ]
    },
    {
        label: '逻辑判断',
        key: 'logic',
        items: [
            'branch',
            'waiting_for_aggregation'
        ]
    }
]

export const iconMap = {
    'get_database_data': <InboxOutlined />,
    'upload_excel_data': <ImportOutlined />,
    'referencing_script_processing': <InteractionOutlined />,
    'writing_script_processing': <FunctionOutlined />,
    'referencing_sub_processes': <PartitionOutlined />,
    'manual_processing': <UserOutlined />,
    'referencing_script_analysis': <SnippetsOutlined />,
    'writing_script_analysis': <SlidersOutlined />,
    'manual_analysis': <PieChartOutlined />,
    'accessing_data_analysis_tool': <DotChartOutlined />,
    'branch': <BranchesOutlined />,
    'waiting_for_aggregation': <ClusterOutlined />,
}

export const titleMap = {
    'get_database_data': '从数据库导入',
    'upload_excel_data': '手动上传',
    'referencing_script_processing': '引用脚本处理',
    'writing_script_processing': '撰写脚本处理',
    'referencing_sub_processes': '引用子流程',
    'manual_processing': '人工处理数据',
    'referencing_script_analysis': '引用脚本分析',
    'writing_script_analysis': '撰写脚本分析',
    'manual_analysis': '人工分析节点',
    'accessing_data_analysis_tool': '接入分析工具',
    'branch': '分支',
    'waiting_for_aggregation': '聚合',
}

export const graphConfig = {
    selectOptions: {
        enabled: true,
        multiple: true,
        rubberband: true,
        movable: true,
        showNodeSelectionBox: true,
        pointerEvents: "none",
        modifiers: ['ctrl', 'meta'],
    },
    connectionOptions: {
        snap: true,
        allowNode: false,
        allowBlank: false,
        allowLoop: false,
        highlight: false,
        anchor: 'center',
        router: 'orth',
        connector: 'rounded',
    },
    connectionEdgeOptions: {
        animated: true,
        draggable: false,
        selected: false,
        tools: ['vertices'],
        attrs: {
            line: {
                stroke: '#a1a1a1',
                strokeWidth: 2,
                targetMarker: {
                    name: 'block',
                    width: 14,
                    height: 10,
                },
            },  
        },
        zIndex: 0,
    },
    history: {
        beforeAddCommand: (event, args) => {
            const ignoreList = ["ports", 'tools']
            return !args.options.undoIgnore && !ignoreList.includes(args.key)
        }
    }
}

export const createStartNode = (view) => {
    const svg = view.container;
    const width = svg.clientWidth;
    return {
        shape: 'circle',
        x: width / 2 - 20,
        y: 80,
        width: 40,
        height: 40,
        label: '开始',
        attrs: {
          body: {
            stroke: '#ffa940',
            fill: '#ffd59191',
          },
          label: {
            refX: 0.5,
            refY: -4,
            textAnchor: 'middle',
            textVerticalAnchor: 'bottom',
          },
        },
        ports: customNodePorts
    }
}

export const createEndNode = (view) => {
    const svg = view.container;
    const width = svg.clientWidth;
    const winHeight = window.innerHeight;
    return {
        x: width / 2 - 20,
        y: winHeight - 80,
        width: 40,
        height: 40,
        label: '结束',
        attrs: {
          body: {
            stroke: '#333',
            strokeWidth: 4,
            fill: '#3335',
            rx: 10,
            ry: 10,
          },
          label: {
            refX: 0.5,
            refY: '100%',
            refY2: 4,
            textAnchor: 'middle',
            textVerticalAnchor: 'top',
          },
        },
        ports: customNodePorts
    }
}
import React, { useState } from 'react';
import { QueryBuilderAntD } from '@react-querybuilder/antd';
import { QueryBuilder } from 'react-querybuilder';
import './index.less';

const fields = [
  { name: 'firstName', label: 'First Name' },
  { name: 'lastName', label: 'Last Name' },
];

const Query = () => {
  const [query, setQuery] = useState({ combinator: 'and', rules: [] });

  return (
    <QueryBuilderAntD>
      <QueryBuilder fields={fields} query={query} showNotToggle={true} onQueryChange={setQuery} />
    </QueryBuilderAntD>
  );
};

export default Query;